import React, { useState } from "react";

type InputProps = {
  onChange?: any;
  onBlur?: any;
  title?: string;
  type?: string;
  value?: string;
  star?: boolean;
  pattern?: string;
  errMsg?: string;
  maxLength?: number;
  counter?: boolean;
  disabled?: boolean;
  placeholder?: string;
  prefix?: string;
  name?: string;
  formTitleFontSize?: string;
  required?: boolean;
  actived?: boolean;
  locked?: boolean;
  label?: string;
  icon?: any;
  onClick?: any;
  readOnly?: boolean;
  additionalInfo?: string;
  margin?: string;
  min?: any;
  max?: any;
  onInput?: any;
  onKeyPress?: any;
  onKeyUp?: any;
};

export const Input = ({
  onChange,
  onBlur,
  title,
  type,
  value,
  star,
  pattern,
  errMsg,
  maxLength,
  counter = false,
  disabled = false,
  placeholder,
  prefix,
  name,
  formTitleFontSize,
  required = false,
  readOnly = false
}: InputProps) => {
  return (
    <React.Fragment>
      <div className="form-input">
        <div className="input-title">
          {title}
          {star ? <span style={{ color: "red" }}> *</span> : ""}
        </div>
        <div
          className={
            "input-text-wrapper" +
            (prefix && type === "tel" ? " input-text-wrapper--tel" : "")
          }
        >
          {prefix && type === "tel" && (
            <span className="input-text-prefix">{prefix}</span>
          )}
          <input
            pattern={pattern}
            type={type}
            className={
              "input-text" +
              (errMsg ? " input--error" : "") +
              (counter && maxLength ? " input--with-counter" : "") +
              (disabled ? " input--disabled" : "")
            }
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            maxLength={maxLength}
            disabled={disabled}
            placeholder={placeholder}
            name={name}
            required={required}
            readOnly={readOnly}
          ></input>
          {counter && maxLength && (
            <span className="input-text-counter">
              {maxLength - value.length}
            </span>
          )}
        </div>

        <div className="error-msg">{errMsg}</div>
      </div>
      <style jsx>{`
        .error-msg {
          color: var(--color-red);
          text-align: right;
          font-size: 12px;
        }
        .form-input {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin: 10px 0 20px 0;
        }
        .input-title {
          font-size: ${formTitleFontSize ? formTitleFontSize : "16px"};
          color: #404040;
          line-height: 19px;
          font-weight: Bold;
          margin-bottom: 8px;
        }
        .input-text {
          border: 0;
          min-width: 0;
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
          color: #000;
          padding: 0.75rem 1.5rem;
          -webkit-appearance: unset;
          border: 1px solid #fff;
          font-size: 16px;
          box-shadow: 0 2px 4px 0 rgba(64, 64, 64, 0.4);
          border-radius: 8px;
          position: relative;
        }
        .input-text-prefix {
          font-size: 16px;
          font-weight: bold;
          color: #000;
          margin-right: 8px;
          padding: 2px 0 0;
        }
        .input-text-wrapper--tel .input-text {
          padding: 0.75rem;
        }
        .input-text:focus {
          font-weight: bold;
        }
        .input--error {
          border: 1px solid var(--color-red);
        }
        .input--with-counter {
          padding-right: 2.5rem;
        }
        .input-text:disabled {
          background-color: #f4f4f4;
          box-shadow: 0 2px 4px 0 rgba(64, 64, 64, 0.4);
          border: none;
          cursor: not-allowed;
          color: #7b7b7b;
        }
        .input-text-wrapper {
          display: flex;
          align-items: center;
          position: relative;
        }
        .input-text-counter {
          position: absolute;
          right: 0;
          background-color: lightgray;
          padding: 3px;
          margin: 0 5px;
          font-size: 11px;
          border-radius: 3px;
        }
        @media only screen and (max-width: 600px) {
          .input-title {
            font-size: 12px;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export const CustomInput = ({
  actived,
  locked,
  label,
  name,
  type,
  pattern,
  disabled,
  required,
  value,
  icon,
  onChange,
  errMsg,
  onClick,
  readOnly,
  additionalInfo,
  margin,
  maxLength,
  min,
  max,
  onInput,
  onKeyPress,
  onKeyUp
}: InputProps) => {
  const [active, setActive] = useState(actived);

  const fieldClassName = `field ${
    (locked ? active : active || value) && "active"
    } ${locked && !active && "locked"}`;

  return (
    <React.Fragment>
      <div className="input-container">
        <div className={fieldClassName}>
          <input
            className={errMsg ? " input--error" : ""}
            id={name}
            type={type}
            value={value}
            pattern={pattern}
            maxLength={maxLength}
            placeholder={label}
            disabled={disabled}
            name={name}
            required={required}
            onChange={onChange}
            onClick={onClick}
            onFocus={() => !locked && setActive(true)}
            onBlur={() => !locked && setActive(false)}
            readOnly={readOnly}
            min={min}
            max={max}
            onInput={onInput}
            onKeyPress={onKeyPress}
            onKeyUp={onKeyUp}
          />
          <label htmlFor={name} className={(errMsg ? "error" : "") + (disabled ? " disabled" : "")}>
            {label}
          </label>
          {icon && type !== "date" ? (
            <img className="input-icon" src={icon} />
          ) : (
              ""
            )}
          <div className="error-msg">{errMsg}</div>
          {!errMsg && additionalInfo && (
            <div className="additional-info">{additionalInfo}</div>
          )}
        </div>
      </div>
      <style jsx>{`
        * {
          box-sizing: border-box;
        }

        .input-container {
          border-radius: 8px;
          background: #f4f4f4;
          margin: ${margin ? margin : "16px"};
        }

        .field {
          width: 100%;
          height: 56px;
          border-radius: 8px;
          position: relative;
          background-color: #f4f4f4;
          transition: 0.1s background-color ease-in-out,
            0.1s box-shadow ease-in-out;
        }

        .field:hover {
          background-color: rgba(255, 255, 255, 0.45);
        }

        .field.active {
          background-color: #f4f4f4;
        }

        .field.active input {
          padding: 24px 16px 8px 16px;
        }

        .field.active input + label {
          top: 4px;
          opacity: 1;
          color: var(--color-black);
        }

        .field.locked {
          pointer-events: none;
        }

        .field input {
          width: 100%;
          height: 56px;
          position: relative;
          padding: 0px 16px;
          border: none;
          border-radius: 8px;
          font-size: 16px;
          font-weight: 400;
          line-height: normal;
          background-color: transparent;
          color: ${disabled ? '#7b7b7b' : '#282828'};
          cursor: ${disabled ? 'not-allowed' : ''};
          outline: none;
          box-shadow: 0px 4px 20px 0px transparent;
          transition: 0.01s background-color ease-in-out,
            0.01s box-shadow ease-in-out, 0.1s padding ease-in-out;
          -webkit-appearance: none;
        }

        .field input::-webkit-input-placeholder {
          color: var(--color-black);
        }
        .field.active input::-webkit-input-placeholder {
          color: #f4f4f4;
        }
        .field input::-moz-placeholder {
          color: rgba(255, 255, 255, 0.8);
        }
        .field input:-ms-input-placeholder {
          color: rgba(255, 255, 255, 0.8);
        }
        .field input:-moz-placeholder {
          color: rgba(255, 255, 255, 0.8);
        }

        .field input + label {
          position: absolute;
          top: 24px;
          left: 16px;
          font-size: 12px;
          font-weight: 600;
          line-height: 24px;
          color: #ffffff;
          opacity: 0;
          pointer-events: none;
          transition: 0.1s all ease-in-out;
        }

        .field input + label.error {
          color: #ec392f;
        }

        .field input + label.disabled {
          color: #7b7b7b;
        }

        .input-icon {
          position: absolute;
          right: 15px;
          bottom: 20px;
        }

        input[type="date"]::-webkit-calendar-picker-indicator {
          color: rgba(0, 0, 0, 0);
          opacity: 1;
          display: block;
          background: url(${icon}) no-repeat;
          width: 20px;
          height: 20px;
          border-width: thin;
        }
        .field .input--error {
          border: 1px solid var(--color-red);
        }
        .error-msg,
        .additional-info {
          font-size: 12px;
          left: 5px;
          position: absolute;
        }
        .additional-info {
          color: #676767;
        }
        .error-msg {
          color: var(--color-red);
        }
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
        }
      `}</style>
    </React.Fragment>
  );
};
